
import { Component, Vue, Watch } from "vue-property-decorator";
import { LoyaltyPointsClaims, LoyaltyPointsClaimsAdmin, Product } from "@/network/api";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import DialogBox from "@/components/DialogBox.vue";
import { ValidationProvider, ValidationObserver, extend, configure } from "vee-validate";
import InvoicePreview from "@/components/rewards-module/InvoicePreview.vue";
import { hasClaim } from "@/utils/applicationClaims";
import handleError from "@/utils/handleError";
import getFileExtension from "@/utils/getFileExtension";
import { CreateProductCommand, LoyaltyPointClaimViewModel, LoyaltyPointsClaimProductCreateUpdateModel, LoyaltyPointUpdateProductListViewModel, ProductListViewModel, ProductLoyaltyPointValueCreateUpdateModel, ProductSkuAliasCreateUpdateViewModel, ReturnsStatus, ReviewLoyaltyPointsClaimAdminCommand, UpdateProductCommand } from "@/api-client";
import sort from "@/utils/sort";
import { Row } from "element-ui";

type ProductDdl = {
  id: string;
  name: string;
  value: string;
  sku: string;
}

@Component({
  name: "ReviewClaim",
  props: {
    id: { default: "" },
  },
  components: {
    InvoicePreview,
    BasicInputField,
    BasicDateField,
    BasicSelectorField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class extends Vue {
  isLoading = true
  NGPProduct: ProductListViewModel[] | null = null;
  formSubmit = false;
  isNewRow = false;
  validImgExtensions = ['png', 'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'webp']
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  confirmText: string = "Ok";
  cancelVisible = true;
  productDdl: ProductDdl[] = [];
  displayInvoiceNumber: string = "";
  duplicateClaimNumber: any = false;
  aliasEditObj: Array<any> = [];
  product: any = { //ProductViewModel
    id: '',
    name: '',
    sku: '',
    productLoyaltyPointValues: [],
    productSkuAlias: []
  };
  claim: LoyaltyPointClaimViewModel = {
    id: "",
    invoiceRelativeUrl: "",
    invoiceNumber: "",
    invoiceDate: "",
    loyaltyPointsClaimStatusId: ReturnsStatus.Pending,
    reviewComment: "",
    reviewedByUserId: "",
    reviewedDate: "",
    workshopId: "",
    submittedByUserId: "",
    loyaltyPointsClaimStatusName: "",
    reviewedByUserDisplayName: "",
    submittedByUserDisplayName: "",
    pageNumbers: [],
    products: [
      {
        id: '',
        quantity: 0,
        value: 0,
        productSku: "",
        productName: "",
        productId: "",
        loyaltyPointsClaimExtractedDataDocumentLineItem: {
          confidence: 0,
          description: "",
          productCode: "",
          quantity: 0,
          unitPrice: 0,
          loyaltyPointsClaimExtractedDataDocumentId: "",
          id: "",
          matchedProductSkus: []
        }
      },
    ],
    workshopName: "",
    companyName: "",
    totalValue: 0,
    dateSubmitted: "",
  };


  totalOverrideValue = 0

  canHandleClaim = {
    manage: false,
  };
  claimErrorCodes: Array<{ title: string, value: string }> = [
    {
      title: "Invoice detail unclear",
      value: "The invoice you recently uploaded onto ServiceBay unfortunately cannot be processed as we cannot identify a valid G.U.D. Holdings product part number. This may be because the image is blurry or the part number is cut off or obscured."
    },
    {
      title: "No valid GUD part number",
      value: "The invoice you recently uploaded onto ServiceBay unfortunately cannot be processed and redeemed for points as it does not contain a valid G.U.D. Holdings product part number. Please review the invoice and ensure you have uploaded the correct document. You may also need to check the invoice to identify that the supplier has used a valid part number on your invoice."
    },
    {
      title: "Invoice already submitted",
      value: "The invoice you recently uploaded onto ServiceBay unfortunately cannot be processed and redeemed for points as the invoice number has already been submitted previously. Please review the invoice and ensure you have uploaded the correct document."
    },
  ];
  activeNames: Array<string> = ['matched', 'partial', 'none']

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get fileExtension() {
    if (this.claim.invoiceRelativeUrl) {
      return getFileExtension(this.claim.invoiceRelativeUrl)
    }
    return ""
  }


  async created() {
    this.canHandleClaim.manage = await hasClaim("ManageClaims", false);
    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  mounted() {
    this.isLoading = false;
    this.loadInitialData();
  }

  @Watch("claim.invoiceDate")
  refreshProductPoints() {
    if (!this.claim.products || this.claim.products.length < 1) {
      return false;
    }
    this.claim.products.forEach(async (product: any, index: number) => {
      this.claim.products[index].value = await this.getProductPoints(product.productId);
    });
  }

  get isApprovedDisabled() {
    var hasProducts = this.claim.products != undefined && this.claim.products.length < 1;
    var hasQuantity = (this.claim.products.filter(item => item.quantity < 1)).length > 0;
    return hasProducts || this.formSubmit || hasQuantity;
  }

  async getProductById(productId: string) {
    var res = await Product.apiProductsIdGet(productId)
    this.product = res.data
    this.aliasEditObj = this.getDeepCopy(this.product.productSkuAliases)
    console.log("res.data", res.data)
  }

  getDeepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj))
  }

  async loadInitialData() {
    if (this.$props.id != "") {
      this.isLoading = true;
      await LoyaltyPointsClaims.apiLoyaltyPointsClaimsIdGet(this.$props.id)
        .then((res) => {
          if (res.data) {
            this.isLoading = false;
            this.claim = res.data;
            if (this.claim.invoiceNumber) {
              this.displayInvoiceNumber = this.claim.invoiceNumber;
              this.testInvoiceNumber();
            }
            if (this.matchedProducts.length < 1) {
              this.activeNames = [];
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          handleError(error);
        });
      this.claim.id = this.$props.id;
    }
  }

  scrollTopTable() {
    this.$nextTick(() => {
      document.getElementById("productSelect")!.scrollIntoView({ behavior: "smooth" });
    });
  }

  openProductSelect() {
    this.isNewRow = true;
    window.setTimeout(() => {
      document.getElementById("productSelect")!.click();
    }, 10);
  }

  submitForm() {
    this.formSubmit = true;

    const payload: ReviewLoyaltyPointsClaimAdminCommand = {
      id: this.claim.id,
      invoiceDate: this.claim.invoiceDate,
      invoiceNumber: this.claim.invoiceNumber,
      loyaltyPointsClaimStatusId: this.claim.loyaltyPointsClaimStatusId,
      reviewComment: this.claim.reviewComment,
      products: this.claim.products as Array<LoyaltyPointsClaimProductCreateUpdateModel>,
      totalOverrideValue: this.totalOverrideValue || null,
    };

    if (this.claim.loyaltyPointsClaimStatusId === "Rejected" || this.claim.loyaltyPointsClaimStatusId === "AwaitingResubmission") {
      payload.products = []
    }
    else {
      payload.products.forEach(item => { if (item.id == "") item.id = null });
    }

    LoyaltyPointsClaimsAdmin.apiLoyaltyPointsClaimsAdminIdPut(this.$props.id, payload)
      .then((res) => {
        if (res) {
          this.formSubmit = false;
          this.$message.success("Claim review successful");
          this.$router.push({ name: "RewardPointList" });
        }
      })
      .catch((error) => {
        this.formSubmit = false;
        this.dialogVisible = false;
        handleError(error);
      });
  }

  handleClose() {
    this.dialogVisible = false;
    this.claim.reviewComment = "";
  }

  handleConfirm() {
    if (this.confirmText === "Send Resubmit Request" || this.confirmText === "Reject Claim") {
      if (this.claim.reviewComment != "" && this.claim.reviewComment != null) {
        this.dialogVisible = true;
        this.submitForm();
      }
    } else if (this.confirmText === "Send Claim" || this.confirmText === "Create Duplicate Claim") {
      this.dialogVisible = true;
      this.submitForm();
    } else {
      this.dialogVisible = true;
    }
  }

  feedbackRequired() {
    this.confirmText = "Send Resubmit Request";
    this.claim.loyaltyPointsClaimStatusId = "AwaitingResubmission";

    this.dialogMessage = "<h2 class='mt-0 text-center'>Please provide a reason before requesting resubmission</h2>";
    this.dialogVisible = true;
  }

  reject() {
    this.confirmText = "Reject Claim";
    this.claim.loyaltyPointsClaimStatusId = "Rejected";
    this.claim.reviewComment = "";

    this.dialogMessage = "<h2 class='mt-0 text-center'>Please provide a reason before rejecting</h2>";
    this.dialogVisible = true;
  }

  approval() {
    this.confirmText = "Send Claim";
    this.claim.loyaltyPointsClaimStatusId = "Approved";

    if (!this.duplicateClaimNumber) {
      this.submitForm();
      return true;
    }

    this.dialogMessage = "<h2 class='mt-0 text-center'>Confirm approval of the claim?</h2>";
    this.dialogVisible = true;
  }

  deleteProduct(index: number) {
    this.claim.products!.splice(index, 1);
  }

  updateQty(qty: number, productRow: any | null = null) {
    this.claim.products[productRow.productIndex].quantity = qty;
  }

  async updateProductList(id: string, productRow: any | null = null) {
    console.log('id', id)
    // console.log ('NGPProduct', this.NGPProduct)
    this.isNewRow = false;
    const isListed = this.claim.products!.filter((prod: LoyaltyPointUpdateProductListViewModel) => prod.productId == id);
    console.log('isListed', isListed)
    const product: ProductDdl | undefined = this.productDdl.find((prod) => prod.id == id);

    if (isListed.length > 0) {
      if (product && product.sku == "NGP") {
        isListed[0].quantity += productRow.quantity;
        //TODO ADD NGP sku alias here:

      } else {

        this.$nextTick(() => {
          const anchor = this.$refs[id]! as Element;
          document.getElementById(anchor.id)!.scrollIntoView({ behavior: "smooth" });
        });
        return;
      }
    }
    if (product && !productRow) {
      this.claim.products!.push({
        productSku: product.sku,
        productId: product.id,
        id: '',
        quantity: 1,
        productName: product.name,
        value: await this.getProductPoints(product.id),
        loyaltyPointsClaimExtractedDataDocumentLineItem: {
          confidence: 0,
          description: "",
          productCode: "",
          quantity: 0,
          unitPrice: 0,
          loyaltyPointsClaimExtractedDataDocumentId: "",
          id: product.id,
          matchedProductSkus: []
        }
      });
    }
    else if (product && productRow) {
      console.log('productRow', productRow)
      console.log('product', product)
      await this.updateProductAlias(product.id, productRow.loyaltyPointsClaimExtractedDataDocumentLineItem.productCode)
      if (product.sku == "NGP") {
        this.claim.products[productRow.productIndex].productName = `${productRow.loyaltyPointsClaimExtractedDataDocumentLineItem.description} `
        
      } else {
        this.claim.products[productRow.productIndex].productName = product.name.replace(` (${product.sku})`, '');
      }
      this.claim.products[productRow.productIndex].productSku = product.sku;
      this.claim.products[productRow.productIndex].productId = id;
      this.claim.products[productRow.productIndex].value = await this.getProductPoints(product.id);
    }
  }

  newAliasEntry(alias: string) {
    this.product.productSkuAliases.push({
      aliasName: alias,
      id: null
    });
  }

  async updateProductAlias(id: any, alias: string) {
    await this.getProductById(id);
        this.newAliasEntry(alias);
        console.log(this.product)
        this.updateProduct(this.product)
  }
  getProductPoints(id: string | undefined) {
    if (!id || id == "") {
      return -1;
    }
    return new Promise<number>((resolve, reject) => {
      Product.apiProductsValueGet(id, this.claim.invoiceDate || new Date().toISOString())
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          } else {
            resolve(-1);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          handleError(error);
        });
    });
  }

  getPointTotal() {
    if (!this.claim.products || this.claim.products.length < 1) {
      return -1;
    }
    let total: number = 0;
    Object.values(this.claim.products!).forEach((product: any) => {
      if (product.value > 0) {
        total += product.quantity! * product.value;
      }
    });
    return total;
  }

  testInvoiceNumber() {
    if (this.claim.invoiceNumber == null || this.claim.invoiceNumber == "") {
      this.duplicateClaimNumber = false;
      return;
    }
    LoyaltyPointsClaimsAdmin.apiLoyaltyPointsClaimsAdminGet(1, 99999, undefined, this.claim.invoiceNumber).then((res) => {
      if (res.data.data) {
        this.duplicateClaimNumber = res.data.data!.filter((claim) => {
          return claim.id != this.$props.id && claim.workshopName == this.claim.workshopName && claim.invoiceNumber == this.claim.invoiceNumber;
        });

        if (this.duplicateClaimNumber.length < 1) {
          this.duplicateClaimNumber = false;
        }
      }
    });
  }

  tableRowClassName(row: any, rowIndex: number) {
    var qty = Number(row.row.quantity);
    var sku = row.row.productSku;
    if (qty > 1 && sku != 'NGP') {
      return 'qty-warning';
    }
    return '';
  }

  get productsMapped() {
    return this.claim.products.map((item: LoyaltyPointUpdateProductListViewModel, index: number) => { return { ...item, productIndex: index } });
  }

  get matchedProducts() {
    const products = this.productsMapped;
    return products.filter((item: LoyaltyPointUpdateProductListViewModel) => item.productId != "" && item.productId != null);//item.id
  }

  get partialProducts() {
    const products = this.productsMapped;
    return products.filter((item: LoyaltyPointUpdateProductListViewModel) => (item.productId == "" || item.productId == null) && item.loyaltyPointsClaimExtractedDataDocumentLineItem.matchedProductSkus.length > 0);
  }

  get notMatchedProducts() {
    const products = this.productsMapped;
    return products.filter((item: LoyaltyPointUpdateProductListViewModel) => (item.productId == "" || item.productId == null) && item.loyaltyPointsClaimExtractedDataDocumentLineItem.matchedProductSkus.length == 0);
  }

  get productMatchComplete() {
    return this.matchedProducts.length == this.claim.products.length;
  }

  // get getNGPProduct() {
  //   var product = this.getProducts('NGP')
  //   return product;
  // }

  getProducts(value: string) {
    Product.apiProductsGet(1, 30, value)
      .then((res) => {
        if (res.data) {
          this.productDdl = sort(Object.values(res.data.data!).map((data: any) => {
            return { id: data.id, name: data.name + ` (${data.sku})`, value: data.point, sku: data.sku };
          }), 'name');
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.dialogVisible = false;
        handleError(error);
      });
  }

  createProduct(product: CreateProductCommand) {
    Product.apiProductsPost({ sku: product.sku, name: product.name, productLoyaltyPointValues: product.productLoyaltyPointValues, productSkuAliases: product.productSkuAliases })
      .then((res) => {
        if (res.data.value) {
          this.$message.success("Saved!")
          this.formSubmit = false
          this.dialogVisible = false
          this.$router.push({ name: 'RewardProductDetail', params: { id: res.data.value } })
        }
      })
      .catch((error) => {
        this.formSubmit = false
        this.dialogVisible = false
        handleError(error)
      })
  }

  updateProduct(product: any) {
    console.log('UpdateProduct', product)
    Product.apiProductsIdPut(product.id, product)
      .then((res) => {
        this.$message.success("Alias added!")

      })
      .catch((error) => {
        //TODO : Don't send if product isn't found or  Alias is already created.
        console.log("error updating product alias", error)
        // handleError(error)
      })
  }

  handleProducts(open: boolean) {
    if (open) {
      this.productDdl = []
    }
  }

  filterProducts(value: string) {
    if (value.length >= 3) {
      this.getProducts(value)
    } else {
      this.productDdl = []
    }
  }

  copyClipboard(path: any) {
    navigator.clipboard.writeText(`${path}`)
    this.$message.success('Value copied.')
  }
}
