
    import { Component, Vue } from 'vue-property-decorator'
    import PointActivityTable from '@/components/rewards-module/PointActivityTable.vue'
    import BasicDateRangeField from "@/components/form-items/BasicDateRangeField.vue"
    import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue"
    import { LoyaltyPointTransactions, Workshops } from "@/network/api"
    import { WorkshopViewModel } from "@/api-client"
    import store from "@/store"
    import handleError from '@/utils/handleError'
import sort from '@/utils/sort'

    @Component({
        name: '',
        components: {
            PointActivityTable,
            BasicDateRangeField,
            BasicSelectorField
        }
    })
    export default class extends Vue {
        isLoading = false
        workshopDdl: Array<WorkshopViewModel> = []
        selectedId = ""
        createDate = {
            startDate: "",
            endDate: "",
        }
        expireDate = {
            startDate: "",
            endDate: "",
        }

        created() {
            if(!this.isWorkshopReport) {
                if(this.workshopId || this.$route.params?.workshop) {
                    this.getInitialWorkshop(this.workshopId || this.$route.params?.workshop)
                } else {
                    this.getWorkshops(true)
                }
            } else {
                this.selectedId = this.workshopId || this.$route.params?.workshop || ""
            }
        }

        get workshopId() {
            return store.getters["user/workshopId"];
        }

        get isWorkshopReport() {
            return this.workshopId ? true : false;
        }

        handleWorkshops(open: boolean) {
            if(open) {
                this.workshopDdl = []
            }
        }

        filterWorkshops(value: string) {
            if(value.length >= 3) {
                this.getWorkshops(false, value)
            } else {
                this.workshopDdl = []
            }
        }

        getInitialWorkshop(workshopId:string) {
            Workshops.apiWorkshopsIdGet(workshopId)
            .then((res) => {
                if (res.data) {
                    const workshop = res.data;
                    this.isLoading = false;
                    this.workshopDdl = [{
                        id: workshop.id,
                        name: workshop.name,
                        workshopStatusId: workshop.workshopStatusId,
                        workshopUsers: []//TODO: fix
                    }]
                    this.selectedId = workshopId;
                }
            })
            .catch((error) => {
                this.isLoading = false;
                handleError(error)
            });
        }

        getWorkshops(initial = false, value: string | undefined = undefined) {
        Workshops.apiWorkshopsGet(1, initial ? 1 : 30, value)
            .then((res) => {
                if (res.data) {
                    this.workshopDdl = sort(res.data.data, 'name') as WorkshopViewModel[]

                    if(this.workshopDdl.length && initial && !this.selectedId) {
                        const field = document.getElementById('workshop');
                        if(field) {
                            setTimeout(() => {
                                field.focus();
                            }, 300);
                        } else {
                            this.selectedId = this.workshopDdl[0].id as string
                        }
                    }
                }
            })
            .catch((error) => {
                this.isLoading = false
                handleError(error)
            })
    }

        async submitForm() {
            this.isLoading = true
            LoyaltyPointTransactions.apiLoyaltyPointTransactionsReportGet(1, 99, "", this.selectedId, this.createDate.startDate, this.createDate.endDate, this.expireDate.startDate, this.expireDate.endDate,
            {
              responseType: 'arraybuffer',
            })
                .then(async (res) => {
                    if(res.status == 200) {
                        this.isLoading = false
                        await this.saveXSL(res.data)
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    handleError(error)
                })
        }

        async saveXSL(data:any) {
            var assetBlob = new Blob([data], {type:"application/xlsx"});
            var assetUrl = URL.createObjectURL(assetBlob);
            var downloadLink = document.createElement("a");
            downloadLink.href = assetUrl;
            downloadLink.download = `Workshop GBucks Activity Report - ${new Date().toISOString().replaceAll("T"," ").replaceAll("Z","").replaceAll(":","--")}.xlsx`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }
