
import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import DialogBox from "@/components/DialogBox.vue";
import DialogBoxFullscreen from "@/components/DialogBoxFullscreen.vue";
import { User, Role, Workshops } from "@/network/api";
import { RoleListViewModel, UserViewModel, WorkshopViewModel, UpdateUserCommand, WorkshopStatus } from "@/api-client";
import { ValidationProvider, ValidationObserver, extend, configure } from "vee-validate";
import { hasClaim } from "@/utils/applicationClaims";
import handleError from "@/utils/handleError";
import sort from "@/utils/sort";

@Component({
  name: "UserDetail",
  props: {
    id: { default: "" },
  },
  components: {
    BasicInputField,
    BasicSelectorField,
    ValidationProvider,
    ValidationObserver,
    DialogBox,
		DialogBoxFullscreen
  },
})
export default class extends Vue {
  isLoading = true;
  formSubmit = false;
  displayName = "";
  user: UserViewModel = {
    id: "",
    displayName: "",
    firstName: "",
    lastName: "",
    email: "",
    roleIds: [],
    workshopRoles: [],
    cellNumber: "",
    acceptedPrivicyPolicy: true,
    isEnabled: false,
    isWorkshopUser: false,
    isVerified: null,
    workshopName: null
  };
  workshopRolesEditObj: Array<any> = [];
  rolesDdl: Array<{ id: string; name: string, description: string | null | undefined }> = [];
  workshopDdl: Array<WorkshopViewModel> = [];
  workshopRoleDdl: Array<{ id: string; name: string, description: string | null | undefined }> = [];
  workshopRoleIndex: number = -1;
  fullDialogVisible: boolean = false;
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  confirmText: string = "Ok";
  cancelVisible = true;
  canHandleClaim = {
    manage: false,
  };

  get preventSave() {
    // Prevent save if workshop role is being added or if user is attempting to add both administrative and workshop roles
    return (this.workshopRoleIndex != -1) || (this.user.roleIds.length > 0) && (this.user.workshopRoles.length > 0);
  }

  async created() {
    this.canHandleClaim.manage = await hasClaim("ManageUsers", false);
    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });

    this.$watch("$props.id", () => {
      if (this.$props.id === "") {
        this.user = {
          id: "",
          displayName: "",
          firstName: "",
          lastName: "",
          email: "",
          roleIds: [],
          workshopRoles: [],
          cellNumber: "",
          acceptedPrivicyPolicy: true,
          isEnabled: false,
          isWorkshopUser: false,
          isVerified: null,
          workshopName: null
        };
      }
    });
  }

  mounted() {
    this.isLoading = false;
    this.loadInitialData();
  }

  loadInitialData() {
    this.getRoles();

    if (this.$props.id != "") {
      this.isLoading = true;
      User.apiUsersIdGet(this.$props.id)
        .then((res) => {
          if (res.data) {
            this.isLoading = false;
            this.dialogVisible = false;
            this.user = res.data;
            this.displayName = this.user.displayName || `${this.user.firstName} ${this.user.lastName}`;
            this.workshopRolesEditObj = this.getDeepCopy(this.user.workshopRoles);
            this.workshopDdl = this.user.workshopRoles.map((item) => { 
              return {
                id: item.workshopId,
                name: item.workshopName,
                workshopStatusId: WorkshopStatus.New,
                workshopUsers: []//TODO: fix
              }
            })
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.dialogVisible = false;
          handleError(error)
        });
    }
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      this.$router.push({ name: "UserManagement" });
    } else {
      this.submitForm();
    }
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage = "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  clearDirtyClasses() {
    let dirty = document.querySelectorAll(".dirty");

    if (dirty.length) {
      for (let item of dirty) {
        item.classList.remove("dirty");
      }
    }
  }

  submitForm() {
    this.formSubmit = true;
    if (this.user.id != "") {
      this.updateUser();
    } else {
      this.createUser();
    }
  }

  createUser() {
    this.user.displayName = `${this.user.firstName} ${this.user.lastName}`;

    User.apiUsersPost(this.user)
      .then((res) => {
        if (res.data.value) {
          this.$message.success("Saved!");
          this.user.id = res.data.value;
          this.formSubmit = false;
          this.dialogVisible = false;
          this.$router.push({ name: "UserDetail", params: { id: res.data.value } });
          this.displayName = this.user.displayName;
          this.clearDirtyClasses();
        }
      })
      .catch((error) => {
        this.formSubmit = false;
        this.dialogVisible = false;
        handleError(error)
      });
  }

  updateUser(user: UpdateUserCommand = this.user) {
    user.displayName = `${this.user.firstName} ${this.user.lastName}`;

    User.apiUsersIdPut(user.id, user)
      .then((res) => {
        this.$message.success("Saved!");
        this.formSubmit = false;
        this.dialogVisible = false;
        this.displayName = this.user.displayName;
        this.loadInitialData();
      })
      .catch((error) => {
        this.formSubmit = false;
        this.dialogVisible = false;
        handleError(error)
      });
  }

  verifyUser() {
    const updateUser: UpdateUserCommand = { ...this.user, isVerified: true }

    this.updateUser(updateUser)
  }

  async profileLock() {
    const updateUser: UpdateUserCommand = { ...this.user, isEnabled: !this.user.isEnabled }

    this.updateUser(updateUser)
  }

  newWorkshopRoleEntry() {
    if (this.user.workshopRoles == null || this.user.workshopRoles.length < 0) {
      this.user.workshopRoles = [];
      this.workshopRolesEditObj = [];
    }

    this.user.workshopRoles.push({
      workshopId: "",
      roleId: "",
      workshopName: "",
      roleName: "",
    });
    this.workshopRolesEditObj.push({
      workshopId: "",
      roleId: "",
    });
    this.workshopRoleIndex = this.user.workshopRoles.length - 1;
  }

  editworkshopRoles(index: number) {
    this.valueTransfer(index, false);
    this.workshopRoleIndex = index;
  }

  saveworkshopRoles(index: number) {
    this.valueTransfer(index, true);
    this.workshopRoleIndex = -1;
  }

  cancelworkshopRoles() {
    if (this.user.workshopRoles[this.workshopRoleIndex].roleId == "" || this.user.workshopRoles[this.workshopRoleIndex].workshopId == "") {
      this.deleteworkshopRoles(this.workshopRoleIndex);
    } else {
      this.valueTransfer(this.workshopRoleIndex, false);
    }
    this.workshopRoleIndex = -1;
  }

  deleteworkshopRoles(index: number) {
    this.user.workshopRoles.splice(index, 1);
    this.workshopRolesEditObj.splice(index, 1);
  }

  getDeepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  valueTransfer(index: number, isSave: boolean) {
    if (isSave) {
      return Object.keys(this.workshopRolesEditObj[index]).forEach((key: string) => {
        // TODO: not a good fix, revisit
        (this.user.workshopRoles as any)[index][key] = this.workshopRolesEditObj[index][key];
      });
    } else {
      return Object.keys(this.user.workshopRoles[index]).forEach((key) => {
        // TODO: not a good fix, revisit
        this.workshopRolesEditObj[index][key] = (this.user.workshopRoles as any)[index][key];
      });
    }
  }

  getRoles() {
    Role.apiRolesGet(1, 99999)
      .then((res) => {
        if (res.data) {
          this.rolesDdl = Object.values(res.data.data as RoleListViewModel[])
            .filter((data) => data.isWorkshopRole == false)
            .map((data) => {
              return { id: data.id as string, name: data.name as string, description: data.description };
            });
          this.workshopRoleDdl = Object.values(res.data.data as RoleListViewModel[])
            .filter((data) => data.isWorkshopRole == true)
            .map((data) => {
              return { id: data.id as string, name: data.name as string, description: data.description };
            });
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.dialogVisible = false;
        handleError(error)
      });
  }

  handleWorkshops(open: boolean) {
      if(open) {
          this.workshopDdl = []
      }
  }

  filterWorkshops(value: string) {
      if(value.length >= 3) {
          this.getWorkshops(value)
      } else {
          this.workshopDdl = []
      }
  }

  getWorkshops(value: string) {
    Workshops.apiWorkshopsGet(1, 30, value)
      .then((res) => {
        if (res.data) {
          this.workshopDdl = sort(res.data.data, 'name') as WorkshopViewModel[];
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.dialogVisible = false;
        handleError(error)
      });
  }
}
